import logo from './assests/logicpeaks-1.png';
import './App.css';
import {Button, Container, TextField} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import * as React from "react";
import {useRef} from "react";

const App = () => {

    const nameRef = useRef();
    const emailRef = useRef();
    const messageRef = useRef();

    const sendMessage = () => {
        const data = {
            firstName: nameRef.current.value,
            email: emailRef.current.value,
            message: messageRef.current.value
        }
        alert(JSON.stringify(data));
    }

    return (
        <div className="App">
            <header className="App-header">
                <Container fixed>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}
                              alignItems="center"
                              className="logo-container"
                        >

                            <img src={logo} className="App-logo" alt="logo"/>

                            <a
                                className="App-link"
                                href="mailto:info@logicpeaks.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                info@logicpeaks.com
                            </a>

                        </Grid>
                        <Grid item xs={12} md={8} spacing={3} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}>
                            <Grid container>
                                <Grid item xs={12} md={8} mdOffset={2}>
                                    <TextField fullWidth id="name" name="name" autoComplete="off"
                                               label="Name"
                                               variant="outlined" inputRef={nameRef}/>
                                </Grid>

                                <Grid item xs={12} md={8} mdOffset={2}>
                                    <TextField fullWidth id="email" name="email" label="E-mail" variant="outlined"
                                               inputRef={emailRef} />
                                </Grid>
                                <Grid item xs={12} md={8} mdOffset={2}>
                                    <TextField fullWidth
                                               id="message"
                                               name="message"
                                               label="Your Message"
                                               multiline
                                               rows={4}
                                               inputRef={messageRef}
                                    />
                                </Grid>
                                <Grid item xs={12} md={8} mdOffset={2}>
                                    <Button variant="outlined" onClick={sendMessage} color="error">Send</Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Container>

            </header>
        </div>
    );

}

export default App;
